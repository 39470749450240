'use client';
import React, { useState } from 'react';
import { SideNav } from '@/components/layout/SideNav';
import { NavItems } from '@/lib/constants';

import { cn } from '@/lib/utils';
import { useSidebar } from '@/hooks/useSidebar';
import { BsArrowLeftShort } from 'react-icons/bs';

interface SidebarProps {
    className?: string;
}

export default function Navbar({ className }: SidebarProps) {
    const { isOpen, toggle } = useSidebar();
    const [status, setStatus] = useState(false);

    function handleToggle() {
        setStatus(true);
        toggle();
        setTimeout(() => setStatus(false), 500);
    }
    return (
        <nav
            className={cn(
                `relative hidden h-screen border-r pt-20 md:block`,
                status && 'duration-300',
                isOpen ? 'w-72' : 'w-[78px]',
                className
            )}
        >
            <BsArrowLeftShort
                className={cn(
                    'absolute -right-3 top-20 cursor-pointer rounded-full border bg-background text-3xl text-foreground',
                    !isOpen && 'rotate-180'
                )}
                onClick={handleToggle}
            />
            <div className="space-y-4">
                <div className="px-3">
                    <div className="mt-3 space-y-1">
                        <SideNav
                            className="text-background opacity-0 transition-all duration-300 group-hover:z-50 group-hover:ml-4 group-hover:rounded group-hover:bg-foreground group-hover:p-2 group-hover:opacity-100"
                            items={NavItems}
                            onItemSelect={handleToggle}
                        />
                    </div>
                </div>
            </div>
        </nav>
    );
}
