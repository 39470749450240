import {
    BookOpenCheck,
    LayoutDashboard,
    UsersIcon,
    Share2,
    BookHeart,
    Wallet,
    TicketCheck,
    GraduationCap,
    Church,
    Wheat,
    SlidersHorizontal,
    HandHelping,
    Store,
    HeartHandshake,
    PackageCheck,
    CreditCard,
    GitGraph,
    FileWarningIcon,
} from 'lucide-react';

import { type NavItem } from '@/types/NavItem';

export const NavItems: NavItem[] = [
    {
        title: 'Dashboard',
        icon: LayoutDashboard,
        href: '/dashboard',
        color: 'text-sky-500',
    },
    {
        title: 'Cadastros',
        icon: SlidersHorizontal,
        href: '/membros',
        color: 'text-gray-600',
        isChidren: true,
        children: [
            {
                title: 'Pessoas',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/employees',
            },
            {
                title: 'Funções',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-02',
            },
            {
                title: 'Celulas',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
            {
                title: 'Eventos',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
            {
                title: 'Unidades',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
            {
                title: 'Produtos',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
            {
                title: 'Estoque',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
            {
                title: 'Fornecedores',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
            {
                title: 'Aquisições',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
            {
                title: 'Doações Recebidas',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
            {
                title: 'Doações Efetuadas',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
            {
                title: 'Alertas',
                icon: FileWarningIcon,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
        ],
    },

    {
        title: 'Membros',
        icon: UsersIcon,
        href: '/membros',
        color: 'text-gray-600',
        isChidren: true,
        children: [
            {
                title: 'Todos os Membros',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/employees',
            },
            {
                title: 'Example-02',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-02',
            },
            {
                title: 'Example-03',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
        ],
    },
    {
        title: 'Células',
        icon: Share2,
        href: '/membros',
        color: 'text-gray-500',
        isChidren: true,
        children: [
            {
                title: 'Example-01',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/employees',
            },
            {
                title: 'Example-02',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-02',
            },
            {
                title: 'Example-03',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
        ],
    },
    {
        title: 'Ministérios',
        icon: BookHeart,
        href: '/membros',
        color: 'text-gray-500',
        isChidren: true,
        children: [
            {
                title: 'Ministério Infantil',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/employees',
            },
            {
                title: 'Ministério do Louvor',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-02',
            },
            {
                title: 'Ministério de Capelania',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
            {
                title: 'Ministério de Comunicação',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
        ],
    },
    {
        title: 'Food Bank',
        icon: Wheat,
        href: '/membros',
        color: 'text-gray-500',
        isChidren: true,
        isBadge: true,
        badgeVariant: 'destructive',
        bTitle: 'New',
        children: [
            {
                title: 'Beneficiários',
                icon: HandHelping,
                color: 'text-sky-500',
                href: '/foodbank/beneficiaries',
                badgeVariant: 'destructive',
                bTitle: 'New',
            },
            {
                title: 'Fornecedores',
                icon: Store,
                color: 'text-sky-500',
                href: '/foodbank/suppliers',
            },
            {
                title: 'Doações Recebidas',
                icon: HeartHandshake,
                color: 'text-sky-500',
                href: '/foodbank/donations-received',
            },
            {
                title: 'Doações Efetuadas',
                icon: PackageCheck,
                color: 'text-sky-500',
                href: '/foodbank/donations-made',
            },
            {
                title: 'Aquisições',
                icon: CreditCard,
                color: 'text-sky-500',
                href: '/foodbank/acquisitions',
            },
        ],
    },
    {
        title: 'Gestão Financeira',
        icon: Wallet,
        href: '/membros',
        color: 'text-gray-500',
        isChidren: true,
        children: [
            {
                title: 'Example-01',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/employees',
            },
            {
                title: 'Example-02',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-02',
            },
            {
                title: 'Example-03',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
        ],
    },
    {
        title: 'Eventos',
        icon: TicketCheck,
        href: '/membros',
        color: 'text-gray-500',
        isChidren: true,
        children: [
            {
                title: 'Example-01',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/employees',
            },
            {
                title: 'Example-02',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-02',
            },
            {
                title: 'Example-03',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
        ],
    },
    {
        title: 'Ensino e Discipulado',
        icon: GraduationCap,
        href: '/membros',
        color: 'text-gray-500',
        isChidren: true,
        children: [
            {
                title: 'Example-01',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/employees',
            },
            {
                title: 'Example-02',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-02',
            },
            {
                title: 'Example-03',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
        ],
    },
    {
        title: 'Unidades',
        icon: Church,
        href: '/membros',
        color: 'text-gray-500',
        isChidren: true,
        children: [
            {
                title: 'Example-01',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/employees',
            },
            {
                title: 'Example-02',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-02',
            },
            {
                title: 'Example-03',
                icon: BookOpenCheck,
                color: 'text-sky-500',
                href: '/example/example-03',
            },
        ],
    },
    {
        title: 'Relatórios',
        icon: GitGraph,
        href: '/membros',
        color: 'text-gray-500',
        isChidren: false,
    },
];
