'use client';

import Link from 'next/link';

import { type NavItem } from '@/types/NavItem';
import { usePathname } from 'next/navigation';
import { cn } from '@/lib/utils';
import { useSidebar } from '@/hooks/useSidebar';
import { buttonVariants } from '@/components/ui/button';

import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from './subnav-accordion';
import { useEffect, useState } from 'react';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { Separator } from '../ui/separator';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Badge } from '@/components/ui/badge';

interface SideNavProps {
    items: NavItem[];
    setOpen?: (open: boolean) => void;
    onItemSelect?: () => void;
    className?: string;
}

export function SideNav({
    items,
    setOpen,
    onItemSelect,
    className,
}: SideNavProps) {
    const path = usePathname();
    const { isOpen } = useSidebar();
    const [openItem, setOpenItem] = useState('');
    const [lastOpenItem, setLastOpenItem] = useState('');

    useEffect(() => {
        if (isOpen) {
            setOpenItem(lastOpenItem);
        } else {
            setLastOpenItem(openItem);
            setOpenItem('');
        }
    }, [isOpen]);

    return (
        <nav className="space-y-2">
            <ScrollArea className="h-screen rounded-md p-1">
                {items.map((item) =>
                    item.isChidren ? (
                        <Accordion
                            type="single"
                            collapsible
                            className="space-y-1 "
                            key={item.title}
                            value={openItem}
                            onValueChange={(newItem) => {
                                if (!isOpen && onItemSelect) {
                                    onItemSelect(); // Primeiro expandir a SideNav se está recolhida
                                }
                                setOpenItem(
                                    newItem === openItem ? '' : newItem
                                ); // Toggle do item do acordeão
                            }}
                        >
                            <AccordionItem
                                value={item.title}
                                className="border-none "
                            >
                                <AccordionTrigger
                                    className={cn(
                                        buttonVariants({ variant: 'ghost' }),
                                        'group relative flex h-12 justify-between px-4 py-2 text-base duration-200 hover:bg-muted hover:no-underline '
                                    )}
                                >
                                    <div>
                                        <item.icon
                                            className={cn(
                                                'h-5 w-5',
                                                item.color
                                            )}
                                        />
                                    </div>

                                    <div
                                        className={cn(
                                            'absolute left-12 text-base duration-200',
                                            !isOpen && className
                                        )}
                                    >
                                        {item.isBadge ? (
                                            <span>
                                                {item.title}{' '}
                                                <Badge
                                                    variant={item.badgeVariant}
                                                >
                                                    {item.bTitle}
                                                </Badge>
                                            </span>
                                        ) : (
                                            <span>{item.title}</span>
                                        )}
                                    </div>

                                    {isOpen && (
                                        <ChevronDownIcon className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200" />
                                    )}
                                </AccordionTrigger>

                                <AccordionContent className="mt-1 space-y-1 pb-1 pl-2 overflow-hidden">
                                    <ScrollArea className="h-[300px] w-[350px] rounded-md p-4">
                                        {item.children?.map((child) => (
                                            <Link
                                                key={child.title}
                                                href={child.href}
                                                onClick={() => {
                                                    if (setOpen) setOpen(false);
                                                }}
                                                className={cn(
                                                    buttonVariants({
                                                        variant: 'ghost',
                                                    }),
                                                    'group relative flex h-12 justify-start gap-x-3 ',
                                                    path === child.href &&
                                                        'bg-muted font-bold hover:bg-muted'
                                                )}
                                            >
                                                <child.icon
                                                    className={cn(
                                                        'h-5 w-5',
                                                        child.color
                                                    )}
                                                />
                                                <div
                                                    className={cn(
                                                        'absolute left-12 text-md duration-200',
                                                        !isOpen && className
                                                    )}
                                                >
                                                    {child.title}
                                                </div>
                                            </Link>
                                        ))}
                                    </ScrollArea>
                                </AccordionContent>
                            </AccordionItem>
                            <Separator className="my-4" />
                        </Accordion>
                    ) : (
                        <Link
                            key={item.title}
                            href={item.href}
                            onClick={() => {
                                if (!isOpen && onItemSelect) {
                                    onItemSelect(); // Também expandir SideNav se está recolhida ao clicar em um child link
                                }
                                if (setOpen) setOpen(false);
                            }}
                            className={cn(
                                buttonVariants({ variant: 'ghost' }),
                                'group relative flex h-12 justify-start',
                                path === item.href &&
                                    'bg-muted font-bold hover:bg-muted'
                            )}
                        >
                            <item.icon className={cn('h-5 w-5', item.color)} />
                            <span
                                className={cn(
                                    'absolute left-12 text-base duration-200',
                                    !isOpen && className
                                )}
                            >
                                {item.title}
                            </span>
                        </Link>
                    )
                )}
            </ScrollArea>
        </nav>
    );
}
